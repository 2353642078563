import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Information = makeShortcode("Information");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reference"
    }}>{`Reference`}</h1>
    <h2 {...{
      "id": "list-sessions"
    }}>{`List sessions`}</h2>
    <p>{`Return a list of website sessions.`}</p>
    <h3 {...{
      "id": "url"
    }}>{`URL`}</h3>
    <pre><code parentName="pre" {...{}}>{`GET /v1/sessions
`}</code></pre>
    <h3 {...{
      "id": "requirements"
    }}>{`Requirements`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`scopes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`users.sessions:read`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tokens`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/rest-api/introduction#creating-a-personal-access-token"
            }}>{`Personal Access Token`}</a></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "parameters"
    }}>{`Parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Descriptions`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The email address that you have associated with a session via `}<a parentName="td" {...{
              "href": "/javascript-api/methods/#identify"
            }}>{`identify`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`visitor_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The visitor ID.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`page`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of page to start with (default 0, max 10000).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of page's size (default 25, max 100).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`date_from`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`RelativeDateString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO 8601 string or `}<em parentName="td">{`RelativeDateString`}</em>{`. For `}<em parentName="td">{`RelativeDateString`}</em>{` see table below for possible values.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`date_to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`RelativeDateString`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO 8601 string or `}<em parentName="td">{`RelativeDateString`}</em>{`. For `}<em parentName="td">{`RelativeDateString`}</em>{` see table below for possible values.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tz`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IANA timezone. Default `}<em parentName="td">{`Europe/London`}</em>{` if `}<em parentName="td">{`RelativeDateString`}</em>{` is applied.`}</td>
        </tr>
      </tbody>
    </table>
    <Information color="info" mdxType="Information">For date range parameters if you would use different time zone than UTC you must encode <i>+</i> character with <i>%2b</i>.</Information>
    <br />
    <p><strong parentName="p">{`RelativeDateString`}</strong>{` spec.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Descriptions`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TODAY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Today since midnight.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`YESTERDAY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yesterday since midnight.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BEGINNING_OF_WEEK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nearest monday since midnight.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BEGINNING_OF_MONTH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1st of the month since midnight.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BEGINNING_OF_PREV_MONTH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Previous 1st of the month since midnight.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TODAY-7DAYS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exact 7 days ago since midnight.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TODAY-30DAYS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exact 30 days ago since midnight.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "example-requests"
    }}>{`Example requests`}</h3>
    <p>{`Get all sessions that match email criteria.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X GET \\
  https://api.livesession.io/v1/sessions?email=john@example.com \\
  -H 'content-type: application/json' \\
  -H 'Authorization: Bearer {YOUR_PERSONAL_ACCESS_TOKEN}'
`}</code></pre>
    <p>{`Get all sessions that match email and older than `}<inlineCode parentName="p">{`Tue, 05 Oct 2021 17:56:02 +0200`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X GET \\
  https://api.livesession.io/v1/sessions?email=john@example.com&date_from=2021-10-04T12:10:10%2b02:00 \\
  -H 'content-type: application/json' \\
  -H 'Authorization: Bearer {YOUR_PERSONAL_ACCESS_TOKEN}'
`}</code></pre>
    <p>{`Get all sessions from today in Europe/Warsaw time zone.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X GET \\
  https://api.livesession.io/v1/sessions?date_from=TODAY&tz=Europe/Warsaw \\
  -H 'content-type: application/json' \\
  -H 'Authorization: Bearer {YOUR_PERSONAL_ACCESS_TOKEN}'
`}</code></pre>
    <p>{`Get all sessions that match email and between `}<inlineCode parentName="p">{`Tue, 05 Oct 2021 08:10:10 +0000`}</inlineCode>{` and `}<inlineCode parentName="p">{`Tue, 05 Oct 2021 11:10:10 +0000`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X GET \\
  https://api.livesession.io/v1/sessions?email=john@example.com&date_from=2021-10-05T08:10:10Z&date_to=2021-10-05T11:10:10Z \\
  -H 'content-type: application/json' \\
  -H 'Authorization: Bearer {YOUR_PERSONAL_ACCESS_TOKEN}'
`}</code></pre>
    <h3 {...{
      "id": "status-codes"
    }}>{`Status codes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON body`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "example-response"
    }}>{`Example response`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "total": 6,
  "page": {
    "num": 0,
    "size": 10
  },
  "sessions": [
    {
      "id": "9a000f77-8b3e-41a5-6443-b92ac1ec2538",
      "website_id": "f12e633c",
      "session_url": "https://app.livesession.io/app/sessions/e836228e-e633-4ba3-6859-dcc57fc370bb/9a000f77-8b3e-41a5-6443-b92ac1ec2538",
      "creation_timestamp": 1621514771785,
      "duration": 978750,
      "end_timestamp": 1621515750535,
      "active_time": 9473,
      "end_url": "https://livesession.io",
      "expiration_timestamp": 1621517550535,
      "last_event_timestamp": 1621515750535,
      "product": "",
      "device": "Desktop",
      "tags": null,
      "seen": true,
      "referrer": "",
      "start_url": "https://livesession.io",
      "visitor_first_session": true,
      "engagment_score": 0.686962962962963,
      "visitor": {
        "id": "e836228e-e633-4ba3-6859-dcc57fc370bb",
        "ip": "",
        "geolocation": {
          "country_code": "",
          "city": "",
          "region": ""
        },
        "last_session_timestamp": 1621514771785,
        "first_session_timestamp": 1621514771785
      },
      "resolution": {
        "height": 1080,
        "width": 3840,
        "resolution": ""
      },
      "os": {
        "name": "Linux",
        "version": ""
      },
      "browser": {
        "description": "Chrome 90.0.4430.212 on Linux 64-bit",
        "name": "Chrome",
        "version": "90.0.4430.212"
      },
      "utm": {
        "source": "",
        "medium": "",
        "campaign": "",
        "term": "",
        "content": ""
      },
      "page_views_statistics": {
        "count": 1
      },
      "events_statistics": {
        "clicks": 3,
        "error_clicks": 0,
        "rage_clicks": 0,
        "error_logs": 0
      }
    }
  ]
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      